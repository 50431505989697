import * as React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Can from "../../common/Can";
import InstagramLink from "../../common/InstagramLink";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import SplitButton from "../../common/SplitButton";
import { Chip, Snackbar, Stack, Tooltip } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import PaidIcon from '@mui/icons-material/Paid';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataObject from "@mui/icons-material/DataObject";

const ProfileHeader = ({
  name,
  sport,
  school,
  signed,
  transfer,
  addedOnSite,
  jerseyNumber,
  options,
  profilePicture,
  instagramHandle,
  isInternational,
  collectionHandle,
  phone,
  email,
  preferredPaymentMethod,
  hometown,
}) => {
  const borderColor = signed ? transfer ? "5px solid yellow" : "5px solid green" : "5px solid red";
  const { views } = usePermissionContext();

  const [open, setOpen] = React.useState(false);

  const contact = {
    email: email,
    phone: phone,
    preferredPaymentMethod: preferredPaymentMethod,
    hometown: hometown
  }
  const contactIcon = {
    email: <EmailIcon />,
    phone: <LocalPhoneIcon />,
    preferredPaymentMethod: <PaidIcon />,
    hometown: <HomeIcon />
  }

  const handleClick = (value) => {
    setOpen(true);
    navigator.clipboard.writeText(value);
  };

  const ChipComponent = () => (
    <>
      <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
        {Object.entries(contact).map(([key, value]) => (
          <Tooltip disableFocusListener disableTouchListener title="click to copy.">
            {value && <Chip icon={contactIcon[key]} onClick={() => handleClick(value)} label={value} variant="outlined" />}
          </Tooltip>
        ))}
      </Stack>

      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )

  return (
    <div
      style={{
        gap: "10px",
        width: "100%",
        display: "flex",
        paddingTop: "20px",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ margin: 0 }}>
        {profilePicture ? (
          <img
            style={{
              width: "125px",
              height: "125px",
              borderRadius: "50%",
              border: borderColor,
            }}
            src={profilePicture}
            alt="The Profile Pic"
          />
        ) : (
          <AccountCircleIcon
            style={{
              width: "125px",
              height: "125px",
              borderRadius: "50%",
              border: borderColor,
            }}
          />
        )}
        <div style={{ margin: "auto", textAlign: "center" }}>
          <div>{transfer ? "In Transfer Portal" : ""}</div>
          <div>{signed ? "Signed" : "Not Signed"}</div>
          <div>{isInternational ? "International" : isInternational}</div>
          <div>{addedOnSite ? "On Site" : "Not On Site"}</div>
        </div>
      </div>

      <div>
        <span style={{ fontSize: "64px", fontWeight: "medium" }}>
          {name.replaceAll("  ", " ")}
        </span>
        {/* <span style={{ fontSize: "14px", cursor: "pointer", color: "#1976d2" }}> */}
        {/* {"@" + (instagramHandle ? instagramHandle : "none")} */}
        <div
          style={{
            display: "inline-block",
            verticalAlign: "baseline",
            margin: "0px",
            padding: "0px",
            paddingLeft: "10px",
          }}
        >
          {instagramHandle && instagramHandle.instagramUsername ?
          <InstagramLink
          style={{ height: "35px", margin: "0px", padding: "0px" }}
          handle={instagramHandle.instagramUsername}
           /> :
           null}
          {collectionHandle && <img
            alt={"clickable Shopify icon"}
            style={{
              maxWidth: "100px",
              height: "35px",
              cursor: "pointer",
              marginLeft: "10px"
              // width: "80%",
              // clipPath: "polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%)",
            }}
            src={"/shopify.svg"}
            onClick={() => { window.open("https://influxermerch.com/athletes/" + collectionHandle + "/", "_blank"); }}
          />}
        </div>
        {/* </span> */}
        <div>
          <div style={{ marginBottom: "5px" }}>
            <span
              style={{
                opacity: "0.4",
                fontSize: "14px",
                marginLeft: "0px",
                position: "relative",
                top: "-10px",
              }}
            >
              {(jerseyNumber ? "#" + jerseyNumber : "none") +
                " - " +
                school +
                " " +
                sport}
            </span>
          </div>
        </div>
        {/* Contact/Payment info */}
        <ChipComponent />
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Can action={views.VIEW} items={[views.ATHLETEEDITMODAL]}>
          <SplitButton
            options={options}
          />
        </Can>
      </div>
    </div>
  );
};

export default ProfileHeader;
