import { Alert, CircularProgress, Snackbar } from "@mui/material";
import * as React from "react";
import { getHandleById } from "../../API/instagram";
import { useUserContext } from "../../contexts/ProviderProvider";
import useNotification from "../../hooks/useNotification";

const InstagramLink = (props) => {
  console.log("insta:", props)
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(undefined);

  const user = useUserContext();
  const notify = useNotification();

  const redirect = (handle) => {
    if (handle.length > 0) {
      window.open("http://instagram.com/" + handle + "/", "_blank");
    } else {
      setSnackbar("No Instagram Account For Athlete");
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <img
          alt={"clickable instagram icon"}
          style={{
            maxWidth: "100px",
            ...props.style,
            cursor: "pointer",
            // width: "80%",
            // clipPath: "polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%)",
          }}
          src={"/instagram.svg"}
          onClick={() => {
            if (props.handle) {
              navigator.clipboard.writeText(props.handle)
              redirect(props.handle);
              return
            }
            setLoading(true);
            getHandleById(props.id, user, props.variant)
              .then((response) => {
                navigator.clipboard.writeText(response.data[0].instagramUsername)
                redirect(response.data[0].instagramUsername);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                notify("Failed to get instagram handle");
                setLoading(false);
              });
          }}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(undefined)}
        message={snackbar}
      >
        <Alert severity="error">{snackbar}</Alert>
      </Snackbar>
    </>
  );
};

export default InstagramLink;
